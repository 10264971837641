import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import GreyHeader from "../../components/GreyHeader";
import Article from "../../components/Article";
import BreadcrumbList from "../../components/seo/BreadcrumbList";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import TipsCarousel from "../../components/tipsAndTricks/TipsCarousel";
import { TIPS_AND_TRICKS_LIST } from "../../constants/tipsAndTricksList";

const Index = () => {
    const key = "immobilienbewertungWieFunktioniertDas";

    const { question, answer } = TIPS_AND_TRICKS_LIST[key];

    return (
        <Layout>
            <MetaComponents title={`${question} | miracl`} description={answer} />

            <GreyHeader title={question} />

            <Article hasMarginTop hasColumns>
                <p>
                    Was sind Immobilien wirklich wert? Diese Frage beschäftigt wahrscheinlich so ziemlich jeden von uns
                    irgendwann in seinem Leben. Damit du bei der Antwort nicht einfach ins Blaue raten musst und
                    vielleicht viel zu viel für eine Immobilie bezahlen musst, gibt es gewissen Berechnungsmodelle, mit
                    denen sich der Marktwert einer Immobilie sehr verlässlich bestimmen lässt.
                </p>

                <p>
                    Zwei einfache Möglichkeiten zur Preisbestimmung sind Verkehrswert und Vergleichswert. Beim
                    Verkehrswert nimmt man an, das betreffende Objekt würde vermietet und errechnet von dieser Grundlage
                    weg, was dementsprechend der Verkaufswert sein könnte. Eine einfachere Alternative stellt der
                    Vergleichswert da. Hier werden die Preise ähnlicher Objekte in der Umgebung als Berechnungsgrundlage
                    herangezogen.
                </p>

                <p>
                    Natürlich lässt sich der Wert einer Immobilie aber nicht immer nach rein quantitativen Kriterien
                    bestimmen. Zustand, Qualität der Ausstattung und viele weitere Faktoren unterliegen subjektiven
                    Eindrücken, weshalb es selbst nach einer Bewertung nicht immer einfach ist, sich auf einen genauen
                    Wert zu einigen.
                </p>

                <p>
                    Um sich dies anhand eines Beispiels besser vorstellen zu können, haben wir eine Immobilie mit Hilfe
                    einer Bewertungssoftware schätzen lassen. Die Eckpunkte dafür waren folgende:
                </p>
                <ul>
                    <li>Wohnung</li>
                    <li>Adresse: Margaretenplatz 4</li>
                    <li>Baujahr: 1884</li>
                    <li>Wohnfläche: 77 Quadratmeter</li>
                    <li>Balkon</li>
                    <li>Kellerfläche: 5 Quadratmeter</li>
                    <li>Aufzug vorhanden</li>
                    Aus diesen Daten wurde beispielhaft folgender Schätzwert ermittelt: 449.580€
                </ul>

                <p>
                    Am Ende steht bei Immobilien natürlich immer die Frage: Wie entwickelt sich der Wert? Und genau da
                    gehen die Meinungen oft weit auseinander. Wer sich eine unabhängige Zweitmeinung holt, ist also ganz
                    bestimmt bestens beraten.
                </p>
            </Article>

            <TipsCarousel ignoreId={key} title={"Andere Tips"} />

            <BreadcrumbList page={key}></BreadcrumbList>

            <ArticleStructuredData
                page={key}
                heading={question}
                description={answer}
                datePublished="2022-09-12"
                dateModified="2022-09-12"
            />
        </Layout>
    );
};

export default Index;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["header", "footer"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
